(function() {
  'use strict';

  angular
    .module('medsquire.pro')
    .config(proPasswordResetRoutes);

  function proPasswordResetRoutes($stateProvider, $urlRouterProvider) {
    $stateProvider.state('propassword_reset', {
      url: '/propassword_reset',
      cache: false,
      templateUrl: 'pro/password_reset/password_reset.html',
      controller: 'ProPasswordResetController',
      controllerAs: 'vm'
    })
  }
})();
