(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.evaluate')
    .controller('EventPresenceEvaluateController', EventPresenceEvaluateController);

  /* @ngInject */
  function EventPresenceEvaluateController($q, MdsqreAuth, $mdsqre, $state, MdsqreHelpers, MdsqreApiEvents, blockUI, $log, $ionicPopup) {

    var vm = this;
    vm.title = 'Qualificar';

    vm.savePresenceConfirmation = savePresenceConfirmation;

    activate();

    ////////////////

    function activate() {
      fetchEvent($state.params.eventId);
      vm.context = MdsqreHelpers.getContext();

      switch (vm.context.short) {
        case 'int':
          vm.observationLegend = 'Observações visíveis apenas para Preceptoria e Coordenação.';
          break;

        case 'uni':
          vm.observationLegend = 'Observações visíveis apenas para Preceptoria e Coordenação.';
          break;

        case 'pro':
          vm.observationLegend = 'Observações visíveis para Médico e Gestora.';
          break;
      }

      vm.attendantLabel = vm.context.attendant_label;
    }

    function fetchEvent(eventId) {
      MdsqreApiEvents.view(eventId)
        .then(function(eventResponse) {
          vm.event = eventResponse.data;
          vm.ratings = { intern: { rate: 1 }, event: { rate: 1 }, self: { rate: 1 } };
          vm.canRateAttendant = vm.event.canRateAttendant();
          vm.canRateAuditor = vm.event.canRateAuditor();
          vm.canRateActivity = vm.event.canRateActivity();
          console.log(vm.event);
        })
        .catch(function(error) {
          $log.log(error);
        });
    }

    function savePresenceConfirmation() {
      if (!vm.event.id) {
        alert('Erro: atividade não encontrada.');
        return;
      }
      blockUI.start();
      confirmPresence()
        .then(function(response) {
          $state.go('*.events.view', {
            eventId: vm.event.id
          });
        })
        .catch(function(error) {
          $ionicPopup.alert({
            title: 'Aconteceu algo errado...',
            template: 'Erro: ' + error.print()
          });
          $state.go('*.events.view', {
            eventId: vm.event.id
          });
        })
        .finally(function() {
          blockUI.stop();
        });
    }

    function confirmPresence() {

      var deferred = $q.defer();
      var user = MdsqreAuth.getUser();

      //var attendant = vm.event.getAttendants()[0];

      var monitor = vm.event.getMonitors()[0];
      var presence = {
        user_id: user.id,
        event_id: vm.event.id,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: 'presence_confirmation',
      };

      presence.ratings = [
        { key: 'event.auditor.evaluation_to_event', value: vm.ratings.event.rate },
        { key: 'event.auditor.evaluation_to_attendant', value: vm.ratings.intern.rate },
        { key: 'event.auditor.evaluation_to_auditor', value: vm.ratings.self.rate },
      ];
      /*presence.ratings = [
        { type: 'event', rate: vm.ratings.event.rate },
        { type: 'intern_performance', rate: vm.ratings.intern.rate, rateable_type: 'App\\User', rateable_id: attendant.id },
        { type: 'self_monitor', rate: vm.ratings.self.rate, rateable_type: 'App\\User', rateable_id: monitor.id },
      ];*/

      presence.additional_info = {
        avaliator: monitor,
        rating: vm.ratings,
        obs: vm.obs
      };

      $mdsqre.requester().events($state.params.eventId).presences().save(presence)
      .then(function (response) {
        console.log(response)
        deferred.resolve(presence);
      })
      .catch(function (error) {
        console.error(error)
        deferred.reject(error);
      });

      return deferred.promise;


      /*var attendant = vm.event.getAttendants()[0];
      var monitor = vm.event.getMonitors()[0];
      var presence = {
        attendant_id: attendant.id,
        event_id: vm.event.id,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: 'presence_confirmation',
      };
      presence.ratings = [
        { type: 'event', rate: vm.ratings.event.rate },
        { type: 'intern_performance', rate: vm.ratings.intern.rate, rateable_type: 'App\\User', rateable_id: attendant.id },
        { type: 'self_monitor', rate: vm.ratings.self.rate, rateable_type: 'App\\User', rateable_id: monitor.id },
      ];
      presence.additional_info = {
        avaliator: monitor,
        rating: vm.ratings,
        obs: vm.obs
      };
      return MdsqreApiEvents.presence(presence);*/

    }
  }
})();
