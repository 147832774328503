(function() {
  'use strict';

  angular
    .module('medsquire.settings.user.business')
    .controller('SettingsUserBusinessController', SettingsUserBusinessController);

  /* @ngInject */
  function SettingsUserBusinessController($scope, $q, MdsqreApiUsers, MdsqreApi, CoreEnvironment, _UserModel, $log, $ionicPopup) {
      var vm = this;
      vm.updateSettings = updateSettings;



      function updateSettings(){
      }

  }

})();
