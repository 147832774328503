(function () {
  'use strict';

  angular
    .module('medsquire.events.contestation.view')
    .controller('ContestationViewController', ContestationViewController);

  /* @ngInject */
  function ContestationViewController($scope, $q, $state, MdsqreApiContestation, MdsqreApiEvents, $log, MdsqreAuth, MdsqreHelpers, $ionicModal, $mdsqre, $ionicPopup) {

    var vm = this;
    vm.title = 'Contestação';

    vm.comment = comment;
    vm.agree = agree;
    vm.resolve = resolve;
    vm.proposalChange = proposalChange;
    vm.formatDate = formatDate;
    vm.getCommentClass = getCommentClass;
    vm.getCommentAuthor = getCommentAuthor;
    vm.boxTimeChange = boxTimeChange;

    vm.proposedTotalMinutes;

    activate();

    ////////////////

    $scope.proposeSolution = function () {

      //$scope.closeProposalModal();

      var confirmPopup = $ionicPopup.confirm({
        title: 'Proposta',
        template: 'Você irá realizar uma proposta. Se aceita, irá liberar o plantão para o pagamento.',
        cancelText: 'Não',
        okText: 'Sim'
      }).then(function (res) {
        if (res) {
          var minutes = vm.proposedTotalMinutes;
          var qty = -1 * vm.proposedReduction / 60;
          var proposal = {
            comment: "",
            info: {
              type: 'additive:qty',
              qty_unit: 'hour',
              qty: qty,
              description: 'Contestação'
            }
          };
          console.log(proposal);
          $mdsqre.requester().events(vm.event.id).contestations($state.params.contestationId).proposal().save(proposal)
            .then(function (proposalResponse) {
              $log.log(proposalResponse);
              $state.reload();
              fetchEvent(vm.event.id);
              $ionicPopup.alert({
                title: 'Proposta Enviada!',
                template: 'A proposta de solução da contestação foi enviada.'
              });
            })
            .catch(function (error) {
              $log.error(error);
              alert("Erro", 'Não foi possível remover a unidade', "error");
            });
        }
      });

    }

    function activate() {
      vm.user = MdsqreAuth.getUser();
      vm.userLoaded = true;

      vm.context = MdsqreHelpers.getContext();

      switch (vm.context.short) {
        case 'int':
          vm.commentLegend = 'Comentário visível para Residente, Preceptor e Coordenação.';
          break;

        case 'uni':
          vm.commentLegend = 'Comentário visível para Aluno, Preceptor e Coordenação.';
          break;

        case 'pro':
          vm.commentLegend = 'Comentário visível para Médico e Gestora.';
          break;
      }

      vm.proposedTotalMinutes = 0;

      fetchEvent($state.params.eventId).then(
        function (response) {
          vm.activityDuration = vm.event.service ? vm.event.service.summary.qty : false; //duração original do plantão em horas
          vm.activityMinutes = vm.activityDuration * 60; //duração original do plantão em minutos
          vm.formatedActivityDuration = moment.duration(vm.activityMinutes, "minutes").format("HH:mm");
          vm.MaxMins = vm.activityMinutes;
          vm.MaxHours = parseInt(vm.activityMinutes / 60);
          vm.proposedServiceHours = vm.activityDuration; //duração proposta do serviço (campo horas)
          vm.proposedServiceMinutes = 0; //duração proposta do serviço (campo minutos)
          //vm.proposedTotalMinutes = vm.MaxMins;//720; //duração proposta do serviço (total de minutos)
          vm.proposedReduction = 0;
          vm.eventLoaded = true;

        }
      );
      fetchContestation($state.params.eventId, $state.params.contestationId).then(
        function (response) {
          // vm.context = MdsqreHelpers.getContext().short;
          vm.canComment = vm.contestation.canComment();
          vm.canResolve = vm.contestation.canResolve();
          vm.canRate = vm.contestation.canRate();
          vm.canCommentResolution = vm.contestation.canCommentResolution();
          vm.canAgree = vm.contestation.canAgree();
          vm.canProposeSolution = vm.contestation.canProposeSolution();
          vm.auditorIsSecret = vm.contestation.auditorIsSecret();
          vm.isSuperAuditor = vm.user.hasRole('auditor:super');
          vm.contestationLoaded = true;
          //### HACK! ###
          if (vm.contestation.additional_info && vm.contestation.additional_info.system) {
            vm.contestation.creator.name = 'Sistema';
          }
        }
      );
    }

    /*$ionicModal.fromTemplateUrl('shared/modules/events/contestation/view/proposal.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function (modal) {
      $scope.modalProposal = modal;
    });

    function openProposalModal() {
      $scope.modalProposal.show();
    }

    $scope.closeProposalModal = function () {
      $scope.modalProposal.hide();
    }*/

    function proposal(id) {
      if (!vm.yesDoProposal) {
        return false;
      }
    }

    function fetchEvent(eventId) {
      var deferred = $q.defer();
      MdsqreApiEvents.view(eventId)
        .then(function (eventResponse) {
          vm.event = eventResponse.data;
          console.log(vm.event);
          vm.event.getCheckinInfo();
          vm.event.getCheckoutInfo();
          vm.event.getConfirmationInfo();
          deferred.resolve(eventResponse);
        })
        .catch(function (error) {
          $log.log(error);
        });
      return deferred.promise;
    }

    function fetchContestation(eventId, contestationId) {
      var deferred = $q.defer();
      var contest = {
        event_id: eventId,
        contestation_id: contestationId
      }
      MdsqreApiContestation.view(contest)
        .then(function (contestationResponse) {
          vm.contestation = contestationResponse.data;
          console.log('CONTESTATION', vm.contestation);
          deferred.resolve(contestationResponse);
        })
        .catch(function (error) {
          $log.log(error);
        });
      return deferred.promise;
    }

    function comment(additionalInfo) {
      var comment = {
        event_id: vm.event.id,
        contestation_id: vm.contestation.id,
        title: '',
        body: vm.newComment,
        additional_info: additionalInfo
      };

      MdsqreApiContestation.comment(comment)
        .then(function (response) {
          $state.reload();
        });
    }

    function agree() {
      var confirmPopup = $ionicPopup.confirm({
        title: 'Concorda?',
        template: 'Você está de acordo com a proposta oferecida?',
        cancelText: 'Não',
        okText: 'Sim'
      }).then(function (res) {
        if (res) {
          var agreement = {
            contestable_id: vm.contestation.contestable_id,
            contestation_id: vm.contestation.id,
            reason: vm.newComment
          };

          MdsqreApiContestation.agree(agreement)
            .then(function (response) {
              $state.reload();
            })
            .finally(function () {
              blockUI.stop();
            });
        }
      });
    }

    function resolve() {

      var message = 'Você irá resolver a contestação, confirma?';
      if (vm.context.short == 'pro') {
        message = 'Você irá resolver a contestação. Resolver a contestação irá liberar o plantão para o pagamento.';
      }

      var confirmPopup = $ionicPopup.confirm({
        title: 'Resolver',
        template: message,
        cancelText: 'Não',
        okText: 'Sim'
      }).then(function (res) {
        if (res) {

          var params = {};
          params.comment = vm.newComment;
          if (vm.canRate) {
            params.ratings = [
              { key: 'event.auditor.evaluation_to_attendant', value: vm.ratings.intern.rate },
              { key: 'event.auditor.evaluation_to_event', value: 2.5 },
              { key: 'event.auditor.evaluation_to_auditor', value: vm.ratings.self.rate }
            ]
          };

          $mdsqre.requester().events($state.params.eventId).contestations(vm.contestation.id).resolve().save(params).
            then(function (response) {
              console.log(response);
              $state.reload();
            })
            .catch(function (error) {
              console.error(error);
            }).finally(function () {
              blockUI.stop();
            });

        }
      });
    }

    function evaluate(eventId, contestationId, comment) {

      var params = {
        comment: comment,
        ratings: [
          { key: 'event.auditor.evaluation_to_attendant', value: vm.ratings.intern.rate },
          { key: 'event.auditor.evaluation_to_event', value: 2.5 },
          { key: 'event.auditor.evaluation_to_auditor', value: vm.ratings.self.rate }
        ],
      };

      $mdsqre.requester().events(eventId).contestations(contestationId).resolve().save(params).
        then(function (response) {
          console.log(response);
          $state.reload();
          deferred.resolve(response);
        })
        .catch(function (error) {
          console.error(error);
          deferred.reject(error);
        });

    }

    function proposalChange() {
      vm.proposedReduction = vm.proposedTotalMinutes; // - vm.activityMinutes;
      vm.proposedServiceHours = parseInt((vm.MaxMins - vm.proposedTotalMinutes) / 60);
      vm.proposedServiceMinutes = (vm.MaxMins - vm.proposedTotalMinutes) - (vm.proposedServiceHours * 60);
    }

    function boxTimeChange() {

      if (vm.proposedServiceMinutes === undefined)
        vm.proposedServiceMinutes = 0;
      if (vm.proposedServiceMinutes > 59)
        vm.proposedServiceMinutes = 59;

      if (vm.proposedServiceHours === undefined)
        vm.proposedServiceHours = 0;

      var tmp = vm.proposedServiceMinutes + (60 * vm.proposedServiceHours);

      if (vm.proposedServiceHours == vm.MaxHours) {
        if ((vm.proposedServiceHours * 60) + vm.proposedServiceMinutes > vm.MaxMins)
          vm.proposedServiceMinutes = 0;

      }

      vm.proposedTotalMinutes = vm.MaxMins - (vm.proposedServiceHours * 60 + vm.proposedServiceMinutes);
      vm.proposedReduction = vm.proposedTotalMinutes;

    }

    function getCommentClass(comment) {
      var commentClass = '';
      vm.user.id == comment.user.id ? commentClass += 'my-comment ' : commentClass += 'his-comment ';
      if (comment.additional_info) {
        if (comment.additional_info.agreed == true) {
          commentClass += 'agreed-comment';
        } else if (comment.additional_info.resolved == true) {
          commentClass += 'agreed-comment';
        }
      }
      return commentClass;
    }

    function getCommentAuthor(comment) {
      if (vm.auditorIsSecret) {
        if (vm.contestation.isMyRole('attendant')) {
          if (comment.user.id == vm.user.id) {
            return comment.user.name;
          } else {
            return 'Auditor';
          }
        }
      } else {
        return comment.user.name;
      }
      return comment.user.name;
    }

    function formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm");
    }

  }
})();
