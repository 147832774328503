(function () {
  'use strict';

  angular
    .module('medsquire.pro.settings.user.professional')
    .controller('ProSettingsUserProfessionalController', ProSettingsUserProfessionalController);

  /* @ngInject */
  function ProSettingsUserProfessionalController($scope, $log, $q, $ionicPopup, $ionicModal, $mdsqre, MdsqreAuth) {

    var vm = this;

    vm.defaultSpecialtyTitle = 'Clínica médica';
    vm.title = 'Dados Profissionais';
    
    vm.updateSpecialties = updateSpecialties;

    activate();

    ////////////////

    function activate() {

      fetchSpecialtyList().then(function() {
        var userId = MdsqreAuth.getUser().id;
        $mdsqre.requester().users(userId).view().then(function(user) {
          vm.user = user.data;
          vm.user.crm = vm.user.additional_info.crm_state + vm.user.additional_info.crm;
          $log.log('USER', vm.user);
          setupSpecialties();
        });
      });

      $ionicModal.fromTemplateUrl('pro/settings/user/professional/specialities.html', {
        scope: $scope,
        animation: 'slide-in-up'
      }).then(function (modal) {
        $scope.specialtiesModal = modal;
      });

    }

    function setupSpecialties() {
      var selectedSpecialtiesTitles = [];
      vm.user.specialties.forEach(function(selectedSpecialty) {
        vm.specialtyList.forEach(function(specialty) {
          if (specialty.id == selectedSpecialty.id) {
            specialty.checked = true;
            selectedSpecialtiesTitles.push(specialty.title);
          }
        });
      });
      vm.specialtiesTitles = selectedSpecialtiesTitles.join(', ');
    }

    function fetchSpecialtyList() {
      var deferred = $q.defer();
      $mdsqre.requester().specialties().list().then(function(specialties) {
        vm.specialtyList = specialties.data;
        deferred.resolve(specialties.data);
      }).catch(function (error) {
        $log.log(error);
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function updateSpecialties() {
      var selectedSpecialtiesTitles = [];
      var selectedSpecialtiesIds = [];
      vm.specialtyList.filter(isChecked).forEach(function(specialty) {
        selectedSpecialtiesTitles.push(specialty.title);
        selectedSpecialtiesIds.push(specialty.id);
      });
      vm.specialtiesTitles = selectedSpecialtiesTitles.join(', ');
      $mdsqre.requester().users(vm.user.id).update({ specialties: selectedSpecialtiesIds });
      if (selectedSpecialtiesTitles.length > 0) {
        console.log('SELECTED', selectedSpecialtiesTitles);
      }
    }

    function isChecked(specialty) {
      return specialty.checked == true;
    }

    $scope.openSpecialtiesModal = function () {
      $scope.specialtiesModal.show();
    };

    $scope.closeSpecialtiesModal = function () {
      $scope.specialtiesModal.hide();
    };

    $scope.$on('$destroy', function () {
      $scope.specialtiesModal.remove();
    });

    $scope.$on('modal.hidden', function () {
      updateSpecialties();
    });
    
    $scope.$on('modal.removed', function () {
      
    });

  }
})();
