(function() {
  'use strict';

  console.log("%c%s","background-color:#ff6600; color:white; padding:15px; font-size:28px; font-weight:bold;",'.ENV IS SET TO PROFESSIONAL');

  angular
    .module('medsquire.pro', [
      'medsquire.pro.password_reset',
      'medsquire.pro.terms',
      'medsquire.pro.home',
      'medsquire.pro.finances',
      'medsquire.pro.settings',
    ]);
})();
