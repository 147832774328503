(function() {
  'use strict';

  angular
    .module('medsquire.pro')
    .config(proTermsRoutes);

  function proTermsRoutes($stateProvider, $urlRouterProvider) {
    $stateProvider.state('proterms', {
      url: '/proterms',
      cache: false,
      templateUrl: 'pro/terms/terms.html',
      controller: 'ProTermsController',
      controllerAs: 'vm'
    })
  }
})();
