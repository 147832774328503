(function() {
  'use strict';

  angular
    .module('medsquire.settings.user.documents')
    .controller('SettingsUserDocumentsController', SettingsUserDocumentsController);

  /* @ngInject */
  function SettingsUserDocumentsController($scope, $q, MdsqreApiUsers, MdsqreApi, CoreEnvironment, _UserModel, $log, $ionicPopup, $ionicModal) {
      var vm = this;
      vm.title = '';
      //vm.user = {};
      vm.updateSettings = updateSettings;
      /*vm.userInfo = {};
      vm.fetchUserInfo = fetchUserInfo;*/

      activate();

      vm.chk1 = false;

      ////////////////

      //vm.data = {};
      //Optional
      //vm.countryCode = 'US';
      //Optional

      function activate() {
        //fetchMe();
      }

      function updateSettings(){
      }
  }
})();
