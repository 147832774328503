(function() {
    'use strict';

    angular
        .module('medsquire.pro.terms')
        .controller('ProTermsController', ProTermsController);

    /* @ngInject */
    function ProTermsController($ionicHistory) {
        var vm = this;
        vm.title = 'Termos de Uso';

        vm.goBack = goBack;

        activate();

        ////////////////

        function activate() {
        }

        function goBack() {
            $ionicHistory.goBack();
        }
    }
})();
