(function() {
  'use strict';

  angular
    .module('medsquire.pro.password_reset')
    .controller('ProPasswordResetController', ProPasswordResetController);

  /* @ngInject */
  function ProPasswordResetController($state, $location, $ionicPopup, $ionicHistory, MdsqreAuth, $log) {
    var vm = this;
    vm.title = 'Redefinir a Senha';
    vm.user = {};
    vm.sendNewPassword = sendNewPassword;
    vm.goBack = goBack;

    activate();

    ////////////////

    function activate() {
      if (MdsqreAuth.isAuthenticated()) {
        vm.user = MdsqreAuth.getUser();
      } else {
        vm.user = {};
      }
    }

    function sendNewPassword() {
      if (validatePasswordReset()) {
        var email = vm.user.email;
        var newPassword = vm.user.password;
        var location = $location.protocol() + '://' + $location.host();
        MdsqreAuth.resetPassword({email: email, new_password: newPassword, redirect: location })
          .then(function(resetRequestResponse) {
            $ionicPopup.alert({
              title: 'Confirmação Enviada',
              template: 'Foi enviado um link de confirmação para o email digitado'
            });
          })
          .catch(function(error) {
            $log.error(error);
            $ionicPopup.alert({
              title: 'Erro',
              template: 'Não foi possível requirir a troca de senha'
            });
          })
          .finally(function() {
            goBack();
          });
      }
    }

    function validatePasswordReset() {
      if (typeof vm.user.email == 'undefined') return false;
      if (typeof vm.user.password == 'undefined') return false;
      if (typeof vm.user.password_confirm == 'undefined') return false;
      if (vm.user.password !== vm.user.password_confirm) {
        $ionicPopup.alert({
          title: 'As duas senhas precisam ser iguais',
          template: 'Certifique-se de que digitou a mesma senha nos dois campos.',
        });
        return false;
      }
      return true;
    }

    function goBack() {
      $ionicHistory.goBack();
    }

  }
})();
