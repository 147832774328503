(function() {
    'use strict';

    angular
      .module('medsquire.pro')
      .config(settingsRoutes)

      function settingsRoutes($stateProvider) {

        $stateProvider.state('pro.settings', {
          url: '/settings',
          abstract: true,
          views: {
            'tab-settings': {
              template: '<ion-nav-view name="tab-settings-main"></ion-nav-view>'
            }
          }
        })

        .state('pro.settings.home', {
          url: '/home',
          cache: false,
          views: {
            'tab-settings-main': {
              templateUrl: 'pro/settings/settings.html',
              controller: 'ProSettingsController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user', {
          url: '/user',
          abstract: true,
          views: {
            'tab-settings-main': {
              template: '<ion-nav-view name="nav-user-settings"></ion-nav-view>'
            }
          }
        })

        .state('pro.settings.user.business', {
          url: '/business',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/settings/user/business/business.html',
              controller: 'SettingsUserBusinessController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user.personal', {
          url: '/personal',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/settings/user/personal/personal.html',
              controller: 'ProSettingsUserPersonalController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user.professional', {
          url: '/professional',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/settings/user/professional/professional.html',
              controller: 'ProSettingsUserProfessionalController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.preferences', {
          url: '/preferences',
          cache: false,
          views: {
            'tab-settings-main': {
              templateUrl: 'shared/modules/settings/preferences/preferences.html',
              controller: 'SettingsPreferencesController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user.banking', {
          url: '/banking',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/settings/user/banking/banking.html',
              controller: 'ProSettingsUserBankingController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user.documents', {
          url: '/documents',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/settings/user/documents/documents.html',
              controller: 'SettingsUserDocumentsController',//'SettingsUserProfessionalController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user.contractors', {
          url: '/contractors',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/settings/user/contractors/contractors.html',
              controller: 'SettingsUserContractorsController',//'SettingsUserProfessionalController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.user.password_reset', {
          url: '/password_reset',
          views: {
            'nav-user-settings': {
              templateUrl: 'pro/password_reset/password_reset.html',
              controller: 'ProPasswordResetController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.help', {
          url: '/help',
          views: {
            'tab-settings-main': {
              templateUrl: 'shared/modules/settings/help/help.html',
              controller: 'HelpController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.terms', {
          url: '/terms',
          views: {
            'tab-settings-main': {
              templateUrl: 'pro/terms/terms.html',
              controller: 'ProTermsController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.developer', {
          cache: false,
          url: '/developer',
          views: {
            'tab-settings-main': {
              templateUrl: 'shared/modules/settings/developer/developer.html',
              controller: 'DeveloperController',
              controllerAs: 'vm'
            }
          }
        })

        .state('pro.settings.developerNotifications', {
          cache: false,
          url: '/developer/notifications',
          views: {
            'tab-settings-main': {
              templateUrl: 'shared/modules/settings/developer/developer-local-notifications.html',
              controller: 'DeveloperController',
              controllerAs: 'vm'
            }
          }
        })

      }
})();
