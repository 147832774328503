
(function () {
  'use strict';

  angular
    .module('medsquire.pro.settings.user.banking')
    .controller('ProSettingsUserBankingController', ProSettingsUserBankingController);

  /* @ngInject */
  function ProSettingsUserBankingController($scope, $q, $log, $http, $ionicModal, $ionicPopup, $mdsqre, MdsqreAuth, BANKS, $state) {

    var vm = this;
    vm.title = 'Dados Bancários';

    $scope.disablePF = true;
    $scope.disablePJ = true;

    activate();

    ////////////////

    function fetchPaymentMethods(){
        $mdsqre.requester().users(MdsqreAuth.getUser().id).payments().methods().list()
        .then(function (response) {
            console.log(response);
            vm.paymentMethods = response.data;

            for(var i = 0; i < vm.paymentMethods.length; i++){
                if(vm.paymentMethods[i].bank_customer_type == 'person'){
                  vm.pf.bank = {};
                  //vm.pf.account = {};
                  vm.pf.name = response.data[0].bank_customer_name;
                  vm.pf.bank.name = response.data[0].bank_name;
                  vm.pf.accountType = response.data[0].type;
                  vm.pf.bank.code = response.data[0].bank_number;
                  vm.pf.document = response.data[0].bank_customer_document;
                  vm.pf.account = response.data[0].bank_account_number;
                  //vm.pf.account.digit = response.data[0].bank_account_number_digit;
                  vm.pf.agency = response.data[0].bank_agency_number;
                  vm.pf.accountType = response.data[0].bank_account_type; // checking -> conta corrente | saving -> conta poupança
                  vm.bank_customer_type = response.data[0].bank_customer_type; // person or company
                  //type: 'bank_transfer',
                }
                if(vm.paymentMethods[i].bank_customer_type == 'company'){
                  vm.pj.bank = {};
                  //vm.pf.account = {};
                  vm.pj.name = response.data[0].bank_customer_name;
                  vm.pj.bank.name = response.data[0].bank_name;
                  vm.pj.accountType = response.data[0].type;
                  vm.pj.bank.code = response.data[0].bank_number;
                  vm.pj.document = response.data[0].bank_customer_document;
                  vm.pj.account = response.data[0].bank_account_number;
                  //vm.pf.account.digit = response.data[0].bank_account_number_digit;
                  vm.pj.agency = response.data[0].bank_agency_number;
                  vm.pj.accountType = response.data[0].bank_account_type; // checking -> conta corrente | saving -> conta poupança
                  vm.bank_customer_type = response.data[0].bank_customer_type; // person or company
                  //type: 'bank_transfer',
                }
            }

        })
        .catch(function (error) {
            console.error(error)
        });
    }


    function activate() {
      vm.pf = {};
      vm.pj = {};
      vm.selectedBank = {};
      fetchMe(MdsqreAuth.getUser().id);
      fetchBanks();
      fetchPaymentMethods();

      //deletePaymentMethod(10);

      $ionicModal.fromTemplateUrl('pro/settings/user/banking/banks.html', {
        scope: $scope,
        animation: 'slide-in-up'
      }).then(function (modal) {
        $scope.banksModal = modal;
      });
    }


    function fetchMe(userId) {
      $mdsqre.requester().users(MdsqreAuth.getUser().id).view().then(function (user) {
        vm.user = user.data;
        $log.log('USER', vm.user);
      });
    }

    function fetchBanks() {
      vm.banks = BANKS;
    }


    function deletePaymentMethod(methodId){
        $mdsqre.requester().users(MdsqreAuth.getUser().id).payments().methods(methodId).delete()
        .then(function (response) {
            console.log(response)
        })
        .catch(function (error) {
            console.error(error);
        });
    }


    function updatePaymentMethod(){
      if(findByCustomerType('person') && isValidPFData())
      {
        
      }

      if(findByCustomerType('company') && isValidPFData())
      {
        atualiza('atualiza pj');
      }

      //Se tem payment method salvo pf e tem dado de pf na tela atualiza pf
      //Se tem payment method salvo pj e tem dado de pj na tela atualiza pj

      //Se não tem payment method salvo pf e tem dado de pf na tela salva pf
      //Se não tem payment method salvo pj e tem dado de pj na tela salva pj

      /*if(vm.pf.bank){
        var bankDataPf = {
          type: 'bank_transfer',
          bank_name: vm.pf.bank.name, //'Banco do Brasil',
          bank_number: vm.pf.bank.code, //'001',
          bank_account_type: vm.pf.accountType,//'checking', // checking -> conta corrente | saving -> conta poupança
          bank_account_number: vm.pf.account, //'12345',
          bank_account_number_digit: vm.pf.account,//'9',
          bank_agency_number: vm.pf.agency, //'0416',
          bank_customer_name: vm.pf.name,//'John Doe',
          bank_customer_type: 'person', // person or company
          bank_customer_document: vm.pf.document//'12345678909',
          //additional_info: [ ]
        };
      }*/
    }


    $scope.saveBankData = function(){

      if(!findByCustomerType('company') && isValidPFData())
      {
          if(vm.pj.bank && vm.pj.bank.name){
            var bankDataPj = {
              type: 'bank_transfer',
              bank_name: vm.pj.bank.name, //'Banco do Brasil',
              bank_number: vm.pj.bank.code, //'001',
              bank_account_type: vm.pj.accountType,//'checking', // checking -> conta corrente | saving -> conta poupança
              bank_account_number: vm.pj.account, //'12345',
              bank_account_number_digit: vm.pj.account,//'9',
              bank_agency_number: vm.pj.agency, //'0416',
              bank_customer_name: vm.pj.name,//'John Doe',
              bank_customer_type: 'company', // person or company
              bank_customer_document: vm.pj.document,//'12345678909',
              //additional_info: [  favorite: true ]
            };
            $mdsqre.requester().users(MdsqreAuth.getUser().id).payments().methods().save(bankDataPj)
            .then(function (response) {
                //fetchPaymentMethods();
                $state.go('*.settings.home');
                console.log(response)
            })
            .catch(function (error) {
              console.error(error)
            });
          }
      }

      var pm = findByCustomerType('company');
      if( pm && !vm.pj.bank.name){
        deletePaymentMethod(pm.id);
        $state.go('*.settings.home');
      }

      if(!findByCustomerType('person') && isValidPFData())
      {
          if(vm.pf.bank && vm.pf.bank.name){
            var bankDataPf = {
              type: 'bank_transfer',
              bank_name: vm.pf.bank.name, //'Banco do Brasil',
              bank_number: vm.pf.bank.code, //'001',
              bank_account_type: vm.pf.accountType,//'checking', // checking -> conta corrente | saving -> conta poupança
              bank_account_number: vm.pf.account, //'12345',
              bank_account_number_digit: vm.pf.account,//'9',
              bank_agency_number: vm.pf.agency, //'0416',
              bank_customer_name: vm.pf.name,//'John Doe',
              bank_customer_type: 'person', // person or company
              bank_customer_document: vm.pf.document//'12345678909',
              //additional_info: [ ]
            };

            $mdsqre.requester().users(MdsqreAuth.getUser().id).payments().methods().save(bankDataPf)
            .then(function (response) {
                //fetchPaymentMethods();
                $state.go('*.settings.home');
                console.log(response)
            })
            .catch(function (error) {
              console.error(error)
            });
          }
      }

      pm = findByCustomerType('person');
      if( pm && !vm.pf.bank.name){
        deletePaymentMethod(pm.id);
        $state.go('*.settings.home');
      }

    }

    function disablePFedition(value){
      $scope.disablePF = value;
    }

    function disablePJedition(value){
      $scope.disablePJ = value;
    }

    $scope.openBanksModal = function (personType) {
      vm.bankToUpdate = personType;
      if (personType == 'pf') {
        vm.selectedBank = vm.pf.bank;
      } else {
        vm.selectedBank = vm.pj.bank;
      }
      $scope.banksModal.show();
    };

    function findByCustomerType(type){
      var presult = vm.paymentMethods.find(function(pmethod){
        return pmethod.bank_customer_type == type;
      });
      return presult;
    }

    function isValidPFData(){
      //type: 'bank_transfer',
      if(vm.pf.bank.name && vm.pf.bank.code && vm.pf.accountType
      && vm.pf.account && vm.pf.account && vm.pf.agency &&
      vm.pf.name && vm.pf.document){
        return true;
      }
    }

    function isValidPJData(){
      //type: 'bank_transfer',
      if(vm.pj.bank.name && vm.pj.bank.code && vm.pj.accountType
      && vm.pj.account && vm.pj.account && vm.pj.agency &&
      vm.pj.name && vm.pj.document){
        return true;
      }
    }

    function clearUiPFData(){
        vm.pf.bank.name = ''; //'Banco do Brasil',
        vm.pf.bank.code = ''; //'001',
        vm.pf.accountType = '';//'checking', // checking -> conta corrente | saving -> conta poupança
        vm.pf.account = ''; //'12345',
        vm.pf.account = '';//'9',
        vm.pf.agency = ''; //'0416',
        vm.pf.name = '';//'John Doe',
        //bank_customer_type: '', // person or company
        vm.pf.document = '';//'12345678909',
        //type: 'bank_transfer',
    }

    function clearUiPJData(){
        vm.pj.bank.name = ''; //'Banco do Brasil',
        vm.pj.bank.code = ''; //'001',
        vm.pj.accountType = '';//'checking', // checking -> conta corrente | saving -> conta poupança
        vm.pj.account = ''; //'12345',
        vm.pj.account = '';//'9',
        vm.pj.agency = ''; //'0416',
        vm.pj.name = '';//'John Doe',
        //bank_customer_type: '', // person or company
        vm.pj.document = '';//'12345678909',
        //type: 'bank_transfer',
    }

    function removeBankingData(){
      if(vm.paymentMethods){

        if(vm.bank_customer_type == 'person'){

          clearUiPFData();
        }
        else{

          clearUiPJData();
        }

        var presult = vm.paymentMethods.find(function(pmethod){
          return pmethod.bank_customer_type == vm.bank_customer_type;
        });

        if(presult)
          deletePaymentMethod(presult.id);
      }
    }

    $scope.closeBanksModal = function () {

      $scope.banksModal.hide();
      if (vm.bankToUpdate == 'pf') {
        vm.pf.bank = vm.selectedBank;

        if(vm.pf.bank.name){
          disablePFedition(false);
        }
        else {
          //deletePFData();
          clearUiPFData();
          disablePFedition(true);
        }

      } else {
        vm.pj.bank = vm.selectedBank;

        if(vm.pj.bank.name){
          disablePJedition(false);
        }
        else {
          clearUiPJData();
          disablePJedition(true);
        }
      }
      vm.bankToUpdate = vm.selectedBank;

    };

    $scope.$on('$destroy', function () {
      $scope.banksModal.remove();
    });

    $scope.$on('modal.hidden', function () {
    });

    $scope.$on('modal.removed', function () {

    });

  }
})();
