(function() {
    'use strict';
  
    angular
      .module('medsquire.pro')
      .config(proAuthRoutes);
  
    function proAuthRoutes($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.otherwise('/pro/home/');
  
      $stateProvider.state('login', {
          url: '/login',
          cache: false,
          templateUrl: 'pro/auth/login/login.html',
          controller: 'AuthLoginController',
          controllerAs: 'vm'
        })

        .state('confirm', {
            url: '/confirm',
            cache: true,
            templateUrl: 'pro/auth/confirm/confirm.html',
            controller: 'AuthConfirmController',
            controllerAs: 'vm'
          })
  
        .state('magiclink', {
          url: '/magiclink',
          abstract: true,
          template: '<ui-view>',
        })
  
        .state('magiclink.login', {
          url: '/login',
          cache: false,
          templateUrl: 'shared/modules/auth/magiclink/magiclink.html',
          controller: 'AuthMagiclinkController',
          controllerAs: 'vm'
        })
    }
  })();
  