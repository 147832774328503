(function() {
  'use strict';

  angular
    .module('medsquire.settings')
    .controller('ProSettingsController', ProSettingsController);

  /* @ngInject */
  function ProSettingsController($state, $rootScope, $localStorage, MdsqreAuth, MdsqreHelpers, BadgesService, $log) {
    var vm = this;
    vm.title = 'Ajustes';

    vm.login = login;
    vm.logout = logout;
    vm.MdsqreHelpers = MdsqreHelpers;
    vm.badges = BadgesService;

    activate();

    ////////////////

    function activate() {
      if (MdsqreAuth.isAuthenticated()) {
        $log.log('#### USER LOGGED ####');
        vm.userIsLoggedOn = true;
      }
    }

    function logout() {
      MdsqreAuth.logout()
        .finally(function() {
          vm.userIsLoggedOn = false;
          delete $localStorage.user;
          delete $localStorage.organization;
          $state.go('pro.home', { reload: true });
        });
    }

    function login() {
      $rootScope.$broadcast('medsquire.login.modal', { onSuccess: redirectAfterLogin });
    }

    function redirectAfterLogin() {
      vm.userIsLoggedOn = true;
      $state.go('pro.home');
    }
  }
})();
