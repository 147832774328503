(function() {
  'use strict';

  angular
    .module('medsquire.settings.user.contractors')
    .controller('SettingsUserContractorsController', SettingsUserContractorsController);

  /* @ngInject */
  function SettingsUserContractorsController($scope, $q, MdsqreApiUsers, MdsqreApi, CoreEnvironment, _UserModel, $log, $ionicPopup) {
      var vm = this;
      vm.updateSettings = updateSettings;



      function updateSettings(){
      }

  }

})();
