(function() {
  'use strict';

  angular
    .module('medsquire.pro')
    .config(proRoutes)
    .run(routeWatcher);

  function proRoutes($stateProvider) {

    $stateProvider.state('pro', {
      url: '/pro',
      abstract: true,
      controller: 'ProController',
      controllerAs: 'vm',
      templateUrl: 'pro/pro-tabs.html',
    });
  }

  /** @ngInject */
  function routeWatcher($rootScope, $state, $timeout, $sessionStorage, MdsqreStateHandler, MdsqreAuth, MdsqreHelpers, APP_CONTEXTS) {

    MdsqreStateHandler.watch({ onLoginSuccess: redirectAfterLogin });

    function redirectAfterLogin(stateToGo) {
      console.log('#### REDIRECTING ####', stateToGo);
      if (stateToGo) {
        $state.go(stateToGo.name);
      }
      stateToGo = null;
    }
  }
})();
