(function() {
  'use strict';

  angular
    .module('medsquire.pro.finances')
    .config(proFinancesRoutes)

  function proFinancesRoutes($stateProvider) {
    $stateProvider.state('pro.finances', {
      url: '/finances',
      cache: false,
      auth: true,
      views: {
        'tab-home': {
          templateUrl: 'pro/finances/finances.html',
          controller: 'ProFinancesController',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
