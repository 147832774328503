(function() {
  'use strict';

  angular
    .module('medsquire.pro.home')
    .config(proHomeRoutes)

  function proHomeRoutes($stateProvider) {
    $stateProvider.state('pro.home', {
      url: '/home/',
      cache: false,
      params: {
        reload: null,
      },
      views: {
        'tab-home': {
          templateUrl: 'pro/home/home.html',
          controller: 'ProHomeController',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
