(function () {
  'use strict';

  angular
    .module('medsquire.pro.finances')
    .controller('ProFinancesController', ProFinancesController);

  /* @ngInject */
  function ProFinancesController($q, $scope, $ionicPopup, $state, $filter, MdsqreApiEvents, MdsqreApiMe, TaskQueueService, CoreEnvironment, $log,
    $sessionStorage, MdsqreAuth, OffersCreateService, ExchangesAcceptService, $localStorage) {

    var vm = this;
    vm.title = 'Finanças';

    activate();

    ////////////////

    function activate() {

      vm.monthName = moment().format('LL');

      vm.user = MdsqreAuth.getUser();

      var date = new Date();
      var initialDate = getFirstDayOnMonth(date);
      var lastDate = getLastDayOnMonth(date);

      fetchPastContested().then(function(pastContested) {
        $log.log('CONTESTED', pastContested);
        vm.pastContestedCount = pastContested.length;
      });

      fetchEventsSummary(initialDate, lastDate).then(function (response) {

        var summary = response;

        console.log('EVENTS SUMMARY', summary);

        vm.totalCount = summary.all.count;
        vm.totalValue = summary.all.subtotal;

        vm.finishedCount = summary.finished.count;
        vm.finishedValue = summary.finished.subtotal;

        vm.finishedPendingCount = summary.finished_pending.count;
        vm.finishedPendingValue = summary.finished_pending.subtotal;

        vm.contestedCount = summary.contested.count;
        vm.contestedValue = summary.contested.subtotal;

        vm.ir = summary.validated.tax_ir;
        vm.inss = summary.validated.tax_inss;

        vm.validatedCount = summary.validated.count;
        vm.validatedValue = summary.validated.subtotal + vm.ir + vm.inss;

        vm.payDay = moment().add(1, 'months').set({
          date: 5
        });

        vm.liquido = summary.validated.subtotal; // Já vem com os descontos

      });

      fetchInvoicesSummary(initialDate, lastDate).then(function (response) {

        vm.invoicesSummary = response;

        console.log('INVOICES SUMMARY', vm.invoicesSummary);

      })

    }

    function fetchPastContested() {
      var deferred = $q.defer();
      var query = buildEventsQuery();
      $log.log('QUERY', query);
      MdsqreApiMe.eventsSummary(query)
        .then(function(eventsResponse) {
          deferred.resolve(eventsResponse.data);
        })
        .catch(function(error) {
          $log.error(error);
          return deferred.reject(error);
        });
      return deferred.promise;
    }

    function buildEventsQuery() {
      var query = {};
      var startMonth = moment('01-01-2018', 'MM-DD-YYYY');
      var currentMonth = moment().startOf('month');
      query.from = moment(startMonth).startOf('month').format('YYYY-MM-DD HH:mm:ss');
      query.to = moment(currentMonth).endOf('month').format('YYYY-MM-DD HH:mm:ss');
      query.event_role = 'attendant';
      query.contested = true;
      return query;
    }

    /*
    function contestadosAnteriores() {

      var currentDate = new Date();
      var y = currentDate.getFullYear(),
        m = currentDate.getMonth();
      var date = new Date(y, m - 1, 1);
      var initialDate = getFirstDayOnMonth(date);
      var lastDate = getLastDayOnMonth(date);

      fetchSummary(initialDate, lastDate).then(function (response) {
        var summary = response;
        vm.contestedCount = summary.contested.count;
        vm.contestedValue = summary.contested.value;
        vm.contestadosAnteriores = vm.contestedValue;
      })

    }*/

    function fetchEventsSummary(initialDate, lastDate) {

      var deferred = $q.defer();

      MdsqreApiMe.eventsSummary({
          from: initialDate + ' 00:00:00',
          to: lastDate + ' 23:59:59',
          event_role: 'attendant'
        }).then(function (result) {
          var summary = result.data;
          deferred.resolve(summary);
        })
        .catch(function (error) {
          $log.log(error);
          deferred.reject(error);
        });
      return deferred.promise;

    }

    function fetchInvoicesSummary(initialDate, lastDate) {

      var deferred = $q.defer();

      MdsqreApiMe.invoicesSummary({
          from: initialDate + ' 00:00:00',
          to: lastDate + ' 23:59:59'
        }).then(function (result) {
          var summary = result.data;
          deferred.resolve(summary);
        })
        .catch(function (error) {
          $log.log(error);
          deferred.reject(error);
        });
      return deferred.promise;

    }

    function getFirstDayOnMonth(date) {
      return moment().startOf('month').format("YYYY-MM-DD");
    }

    function getLastDayOnMonth() {
      return moment().endOf('month').format("YYYY-MM-DD");
    }
  }

})();
