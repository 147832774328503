(function() {
  'use strict';

  angular
    .module('medsquire.settings.user.personal')
    .controller('ProSettingsUserPersonalController', ProSettingsUserPersonalController);

  /* @ngInject */
  function ProSettingsUserPersonalController($scope, $log, $ionicPopup, $mdsqre, MdsqreAuth, MdsqreApiUsers) {

    var vm = this;
    vm.title = 'Dados Pessoais';

    vm.updateSettings = updateSettings;

    activate();

    ////////////////

    function activate() {
      vm.countryCode = 'US';
      $scope.onAddressSelection = function (location) {
        //buildAddressString(location);
      };

      fetchMe(MdsqreAuth.getUser().id);
    }

    function fetchMe(userId) {
      $mdsqre.requester().users(userId).view().then(function(user) {
        vm.user = user.data;
        $log.log('USER', vm.user);
      });
    }

    function buildAddressString(location) {
        vm.userInfo.address = {};
        vm.userInfo.address.street = location.street;
        vm.userInfo.address.number = location.number;
        vm.userInfo.address.complement = location.complement;
        vm.userInfo.address.district = location.district;
        vm.userInfo.address.zip = location.zip;
        vm.userInfo.address.city = location.city;
        vm.userInfo.address.state = location.state;
        vm.userInfo.address.country = location.country;

        var tmp = vm.userInfo.address.street + "," +
        vm.userInfo.address.number + "," +
        vm.userInfo.address.complement + "," +
        vm.userInfo.address.district + "-" +
        vm.userInfo.address.zip + "," +
        vm.userInfo.address.city + "-" +
        vm.userInfo.address.state + "," +
        vm.userInfo.address.country;

        var stripped = tmp.split('undefined').join('');
        stripped = stripped.split('null').join('');
        stripped = stripped.replace(/-,+/g,',');
        stripped = stripped.replace(/,+/g,', ');

        //stripped = stripped.split(' ').join(', ');
        stripped = stripped.trim();
        while(stripped.charAt(stripped.length-1)==",")
        {
           stripped = stripped.substring(0, stripped.length-1);
           stripped = stripped.trim();
        }
        vm.address_string = stripped;
    }

    function validateData() {
      if (typeof vm.userInfo.email == 'undefined') return false;
      if (typeof vm.userInfo.name == 'undefined') return false;
      if (typeof vm.userInfo.phone1 == 'undefined') return false;
      if (typeof vm.userInfo.phone2 == 'undefined') return false;
      return true;
    }

    function updateSettings(){
      if (validateData()) {
        var userid = vm.user.id;
        var email = vm.user.email;
        var name = vm.userInfo.name;
        var phone1 = vm.userInfo.phone1;
        //var phone2 = vm.userInfo.phone2;
        //var whattsapp = vm.userInfo.whatsapp;
        var zip;
        if(vm.userInfo.address.zip)
            zip = parseInt(vm.userInfo.address.zip.replace('-',''));

        var userData = {
            id: userid, // required
            name: name,
            //email: email,
            contact: {
                //email: email,
                phone1: phone1,
                //phone2: phone2,
                //whattsapp: whattsapp
            },
            address: {
                street: vm.userInfo.address.street,
                state: vm.userInfo.address.state,
                zip: zip,
                district: vm.userInfo.address.district,
                number: vm.userInfo.address.number,
                city: vm.userInfo.address.city,
                complement: vm.userInfo.address.complement,
                country: vm.userInfo.address.country
            }
        }
        MdsqreApiUsers.update(userData).then(function(response){
          $ionicPopup.alert({
            title: 'Update',
            template: 'Dados pessoais atualizados com sucesso'
          });
        })
        .catch(function(error) {
          $log.error(error);
          $ionicPopup.alert({
            title: 'Erro',
            template: 'Não foi possível atualizar'
          });
        })
        .finally(function() {
          goBack();
        });
      }
    }

  }
})();
