(function () {
  'use strict';

  angular
    .module('medsquire.events.contestation')
    .controller('ContestationCreateController', ContestationCreateController);

  /* @ngInject */
  function ContestationCreateController(MdsqreHelpers, $q, $state, $filter, MdsqreApiEvents, $ionicPopup, MdsqreApiContestation,
    $log, MdsqreAuth, $sessionStorage) {

    var vm = this;
    vm.title = 'Contestação';

    vm.createContestation = createContestation;

    activate();

    ////////////////

    function activate() {
      vm.user = MdsqreAuth.getUser();

      vm.context = MdsqreHelpers.getContext();

      switch (vm.context.short) {
        case 'int':
          vm.reasonLegend = 'Justificativa enviada para Residente e Coordenação.';
          break;

        case 'uni':
          vm.reasonLegend = 'Justificativa enviada para Aluno e Coordenação.';
          break;

        case 'pro':
          vm.reasonLegend = 'Justificativa enviada para Médico e Gestora.';
          break;
      }

      vm.userLoaded = true;
      fetchEvent($state.params.eventId).then(
        function (response) {
          vm.eventLoaded = true;
        }
      );
    }

    function fetchEvent(eventId) {
      var deferred = $q.defer();
      MdsqreApiEvents.view(eventId)
        .then(function (eventResponse) {
          vm.event = eventResponse.data;
          console.log('EVENT', vm.event);
          vm.event.getCheckinInfo();
          vm.event.getCheckoutInfo();
          vm.event.getConfirmationInfo();
          deferred.resolve(eventResponse);
        })
        .catch(function (error) {
          $log.log(error);
        });
      return deferred.promise;
    }

    function createContestation() {
      var contestationObj = {
        contestable_id: vm.event.id,
        reason: vm.contest_reason,
        additional_info: ""
      };
      MdsqreApiContestation.create(contestationObj)
        .then(function (contestResponse) {
          console.log('CONTESTATION', contestResponse);
          $state.go('*.events.contestation.view', {
            eventId: vm.event.id,
            contestationId: contestResponse.data.id
          });
          $sessionStorage.customBack = { backSteps: -3 };
        })
        .catch(function (error) {
          $ionicPopup.alert({
            title: 'Aconteceu algo errado...',
            template: 'Erro: ' + error.print()
          });
          $state.go('*.events.view', {
            eventId: $state.params.eventId
          });
        });
    }

  }
})();
