(function() {
  'use strict';

  angular
      .module('medsquire.pro.settings.user', [
        // 'medsquire.settings.user.personal',
          //'medsquire.settings.user.business',
        'medsquire.pro.settings.user.professional',
        'medsquire.pro.settings.user.banking',
          //'medsquire.settings.user.documents',
          //'medsquire.settings.user.contractors'
        ]);
})();
