(function () {
  'use strict';

  angular
    .module('medsquire.pro')
    .controller('ProController', ProController);

  /* @ngInject */
  function ProController($scope, $log, $ionicModal, $ionicPopup, $mdsqre, MdsqreAuth, MdsqreApiMe, coreEvents) {
    var vm = this;
    vm.title = 'ProController';
    vm.badges = {};
    vm.authModal = {};

    activate();

    ////////////////

    function activate() {}

    ///// AUTH MODAL /////

    $scope.$on('medsquire.login.modal', function (event, options) {
      vm.showAuthModal(options);
    });

    vm.showAuthModal = function (options) {
      if (MdsqreAuth.isAuthenticated()) {
        $log.warn('already authenticated.');
        return;
      }
      if (options) {
        vm.authModal.options = options;
      }
      vm.authModal.step = 'check_mail';
      vm.authModal.modal.show();
    };

    vm.closeAuthModal = function (success) {
      vm.authModal.modal.hide();
      if (vm.authModal.options && success) {
        vm.authModal.options.onSuccess(vm.authModal.options.stateToGo);
        vm.authModal.options = null;
      }
    };

    $ionicModal.fromTemplateUrl('auth-modal.html', {
      scope: $scope,
      animation: 'slide-in-up',
      focusFirstInput: true
    }).then(function (modalInstance) {
      vm.authModal.modal = modalInstance;
    });

    vm.authModal.closeAndRemove = function (modalInstance) {
      return modalInstance.hide()
        .then(function () {
          scope.closingModal();
          return modalInstance.remove();
        });
    }

    vm.authModal.checkMail = function (email) {
      $mdsqre.requester().checkAuth().post({
          email: email
        })
        .then(function (checkResponse) {
          var response = checkResponse.data.data;
          if (response.can_login) {
            vm.authModal.step = 'login';
            vm.authModal.user.name = response.name;
          } else {
            vm.authModal.step = 'register';
          }
        })
        .catch(function (error) {
          $log.error(error);
          $ionicPopup.alert({
            title: 'Falha ao checar email',
            template: "Pro favor, tente novamente"
          });
        });
    }

    vm.authModal.login = function (mode) {
      if (!mode) mode = 'organization';
      var data = {
        email: vm.authModal.user.email,
        password: vm.authModal.user.password
      };
      var request = $mdsqre.requester();
      if (mode == 'user') {
        request.authentication();
      }
      if (mode == 'organization') {
        request.authentication({
          type: 'organization'
        });
      }
      console.log(request.routes);
      request.login(data).then(function (loginResponse) {
          $log.log(loginResponse);
          vm.closeAuthModal(true);
          //$state.reload();
        })
        .catch(function (error) {
          $log.error(error);
          $ionicPopup.alert({
            title: 'Falha no login',
            template: "Email ou senha incorretos. Tente novamente"
          });
        });
    }

    vm.authModal.register = function (user) {
      if (vm.authModal.user.password != vm.authModal.user.password_confirm) {
        vm.authModal.passwordMismatch = true;
        return;
      };
      vm.authModal.passwordMismatch = false;
      user.login = true;
      $mdsqre.requester().auth().register().post(user)
        .then(function (registerResponse) {
          $log.log(registerResponse);
          $ionicPopup.alert({
            title: 'Registrado com Sucesso',
            template: "Um link de confirmação foi enviado para o email " + user.email + ". Confirme sua conta cliando no link enviado pra você."
          });
          vm.closeAuthModal(true);
          $state.reload();
        })
        .catch(function (error) {
          $log.error(error);
          $ionicPopup.alert({
            title: 'Falha ao registrar',
            template: "Ocorreu um erro ao tentar o registro. Por favor tente novamente."
          });
        });
    }

    vm.authModal.resetPassword = function (user) {
      if (vm.authModal.user.password != vm.authModal.user.password_confirm) {
        vm.authModal.passwordMismatch = true;
        return;
      };
      vm.authModal.passwordMismatch = false;
      $mdsqre.requester().resetAuth().post({
          email: user.email,
          new_password: user.password_confirm,
          redirect: $location.protocol() + '://' + $location.host()
        })
        .then(function (checkResponse) {
          $ionicPopup.alert({
            title: 'Requisição Enviada',
            template: "Um link de confirmação foi enviado para o email " + user.email + ". Clique no link em seu email para confirmar a nova senha."
          });
          vm.closeAuthModal();
        })
        .catch(function (error) {
          $log.error(error);
          $ionicPopup.alert({
            title: 'Falha ao checar email',
            template: error.print()
          });
          SweetAlert.swal("Falha ao checar email", error.print(), "error");
        });
    }

  }
})();
