(function() {
  'use strict';

  angular
    .module('medsquire.pro.home')
    .controller('ProHomeController', ProHomeController);

  /* @ngInject */
  function ProHomeController($stateParams) {
    var vm = this;
    vm.title = 'MedSquire';

    activate();

    ////////////////

    function activate() {
      if ($stateParams.reload) {
        setTimeout(function() {
          window.location.reload(true);
        });
      }
    }
    
  }
})();
